import React from 'react';
import HomePageHero from './components/sitecoremodules/HomePageHero';
import EmployerList from './components/sitecoremodules/EmployerList';
import JobsInnerPageHero from './components/sitecoremodules/JobsInnerPageHero';
import JobsBanner from './components/sitecoremodules/JobsBanner';
import JobsBannerWithImage from './components/sitecoremodules/JobsBannerWithImage';
import JobsSearchResults from './components/sitecoremodules/JobsSearchResults';
import SearchCategoryCards from './components/sitecoremodules/SearchCategoryCards';
import HeaderComponent from './components/layout/header/HeaderComponent';
import FooterComponent from './components/layout/footer/FooterComponent';

const Raw = ({ moduleName, ...props }) => {
    const onClick = (e) => {
        e.preventDefault();
        console.log(moduleName, props);
    }
    return <div>{moduleName} does not have a valid react component mapping. <button onClick={onClick} > Click to console log props</button></div>
}


const COMPONENT_MAPPINGS = {
    HomePageHero,
    EmployerList,
    JobsBanner,
    SearchCategoryCards,
    JobsInnerPageHero,
    JobsBannerWithImage,
    JobsSearchResults
}

const ZoneRenderer = ({ contentZone, currentLang = 'en', ...props }) => {
    return <>
        {props.withHeader && <HeaderComponent currentLang={currentLang} />}
        {contentZone.map((module, idx) => {
            let TheModule = Raw;
            if (!!COMPONENT_MAPPINGS[module.ModuleName]) {
                TheModule = COMPONENT_MAPPINGS[module.ModuleName];
            }
            return <TheModule moduleName={module.ModuleName} currentLang={currentLang} {...module.props} />
        })}
        {props.withFooter && <FooterComponent currentLang={currentLang} />}
    </>
}


export default ZoneRenderer;
