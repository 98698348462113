import React, { createContext, useContext, useEffect, useState } from "react";
import xml2js from 'xml2js';
import JobModal from './../components/panels/results/JobModal'

export const DataContext = createContext<any>({});

export const useData = () => useContext(DataContext);

export const DataProvider = (props) => {
    const { children, xmlData, navigation, homeitem, filterKeywords = "", currentLang, labels = [], ...otherProps } = props;
    let _labels = labels.reduce((acc, post) => {
        let { key, Phrase } = post;
        return { ...acc, [key]: Phrase };
    }, {});
    const [allJobs, setJobs] = useState<any>(null);
    const [filteredJobs, setFilteredJobs] = useState<any>(null);
    const [companies, setCompanies] = useState<any>([]);
    const [loading, setLoading] = useState<any>(true);
    const [jobsCount, setCount] = useState<any>(0);
    const [error, setError] = useState<any>(null);
    const [searchValue, setSearchValue] = useState<any>("");
    const [jobTypeFilter, setJobTypeFilter] = useState<any>([]);
    const [companySubFilter, setCompanySubFilter] = useState<any>([]);
    const [dateSubFilter, setDateSubFilter] = useState<any>("");
    const [jobDuration, setJobDuration] = useState<any>("");
    const [langSubFilter, setLangSubFilter] = useState<any>("");
    const [sortBy, setSortBy] = useState<any>("relevance");
    const [offset, setOffset] = useState(0);
    const [navItems, setNavItems] = useState<any[]>([]);
    const [breadcrumbs, setBreadcrumbs] = useState<any[]>([]);
    const [showMobileFilters, setShowMobileFilters] = useState<any>(false);
    const [jobModal, setJobModal] = useState<any>(null);
    let json; // declare json variable outside of try block

    let parsedKeywords = filterKeywords.toLowerCase().split(',');

    const fetchData = async () => {
        try {
            const text = xmlData
            json = await xml2js.parseStringPromise(text, { mergeAttrs: true, explicitArray: false });
            if (!json?.source?.job) {
                return false;
            }
            const allJobs = JSON.parse(JSON.stringify(json.source.job));
            const allJobsWithDates = allJobs.map(job => {
                return {
                    ...job,
                    parsedDate: new Date(Number(job.pubDate))
                }
            });
            const urlParams = new URLSearchParams(window.location.search);
            const search = urlParams.get('search');

            const searchValues = search?.split(",") || [];
            const _allJobs = allJobsWithDates.filter(j => {
                if (!!filterKeywords && filterKeywords.length > 1) {
                    const jobJSON = JSON.stringify(j).toLowerCase();
                    return parsedKeywords.some(keyword => jobJSON.indexOf(keyword) > -1);
                } else {
                    return true;
                }
            })
            const filtered = _allJobs.filter(job => {
                const jobJSON = JSON.stringify(job).toLowerCase();
                return searchValues.every(value => jobJSON.includes(value.trim().toLowerCase()));
            });
            setSearchValue(search);
            const jobsCount = allJobs.length;
            const companies = [...new Set(_allJobs.map(job => job.employerName))];
            setCompanies(companies);
            setJobs(_allJobs);
            setFilteredJobs(filtered)
            setCount(jobsCount);
            setLoading(false);
        } catch (error) {
            setError(error.message);
            setLoading(false);
        }
    };

    useEffect(() => {
        setOffset(0);
    }, [jobTypeFilter, companySubFilter, dateSubFilter, langSubFilter])

    const setupNavigation = () => {
        let pathname = window.location.pathname;
        let filterValidNavItems = i => !!i.Title;
        let mapActive = i => {
            return { ...i, isActive: i.Url === pathname }
        }
        let _navItems = navigation.filter(filterValidNavItems).map(item => {
            let i = { ...item };
            if (!!i.Children) {
                i.Children = i.Children.filter(filterValidNavItems).map(mapActive);
            }
            if (!!i.Children && i.Children.length === 0) {
                delete i.Children;
            }
            return i;
        }).map(mapActive);
        let crumbs: any[] = [];
        crumbs.push({ Title: homeitem.Title, Url: homeitem.Url });
        _navItems.forEach(item => {
            if (pathname.indexOf(item.Url) === 0) {
                crumbs.push({ Title: item.Title, Url: item.Url });
            }
            if (!!item.Children && item.Children.length > 0) {
                item.Children.forEach(_item => {
                    if (pathname.indexOf(_item.Url) === 0) {
                        crumbs.push({ Title: _item.Title, Url: _item.Url });
                    }
                })
            }

        });
        setBreadcrumbs(crumbs);
        setNavItems(_navItems);
    }

    useEffect(() => {
        if (!!allJobs && allJobs.length > 0) {
            let params = new URLSearchParams(window.location.search)
            let jobID = params.get('jobId')
            if (!!jobID && !!Number(jobID)) {
                let tmpJob = allJobs.filter(j => j.jobID == jobID)
                setJobModal(!!tmpJob && !!tmpJob.length ? tmpJob[0] : null)
            }
        }
    }, [allJobs])

    useEffect(() => {
        fetchData();
        setupNavigation();
    }, []);

    const filterJobs = (e) => {
        e?.preventDefault();
        const searchValues = searchValue.split(",");
        const filtered = allJobs.filter(job => {
            const jobJSON = JSON.stringify(job).toLowerCase();
            return searchValues.every(value => jobJSON.includes(value.trim().toLowerCase()));
        });
        setFilteredJobs(filtered);
    };

    const clearFilters = (e) => {
        e.preventDefault();
        setFilteredJobs(allJobs);
        setJobTypeFilter([])
        setSearchValue("");
        setCompanySubFilter([]);
        setDateSubFilter("");
        setLangSubFilter("");
        setJobDuration("");
    }

    if (loading) {
        return <div>Loading...</div>
    }

    if (error) {
        return <div>Error: {error}</div>
    }

    return (
        <DataContext.Provider value={{
            allJobs, jobsCount, navItems, filterJobs, searchValue, setSearchValue, breadcrumbs,
            filteredJobs, clearFilters,
            jobTypeFilter, setJobTypeFilter,
            jobDuration, setJobDuration,
            companies,
            companySubFilter, setCompanySubFilter,
            dateSubFilter, setDateSubFilter,
            langSubFilter, setLangSubFilter,
            sortBy, setSortBy,
            showMobileFilters, setShowMobileFilters,
            offset, setOffset,
            jobModal, setJobModal,
            pageProps: otherProps,
            labels: _labels
        }}>
            {children}
            {jobModal && <JobModal data={jobModal} currentLang={currentLang} onClose={() => setJobModal(null)} labels={_labels} />}
        </DataContext.Provider>
    );
};
