import React from 'react';
import { useData } from "./../../../contexts/DataContext";

function SearchPanel(props) {

    const { allJobs, jobsCount, filterJobs, searchValue, setSearchValue,
        filteredJobs } = useData();

    const handleSearch = (event) => {
        const searchText = event.target.value;
        setSearchValue(searchText);
    };

    return (
        <section className="panel-search" id="job-searchpanel">
            <div className={`page-width theme`}>
                <p>{props.heading}</p>
                <form className={`form-wrapper`} action="/search">
                    <div className={`input-wrapper`}>
                        <input type="text" placeholder={`${props.placeholder}`} onChange={handleSearch} value={searchValue} />
                    </div>
                    <div className={`button-wrapper`}>
                        <button onClick={filterJobs} className={`btn btn-pill btn-search`}>{props.button}</button>
                    </div>
                </form>
            </div>
        </section>
    );
}

export default SearchPanel;
