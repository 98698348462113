import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import ZoneRenderer from './ZoneRenderer';
import { DataProvider } from "./contexts/DataContext";
declare const window: any;

const JobsApp = ({ withHeader = false, withFooter = false, xmlData, contentZone, Navigation, FilterKeywords, currentLang, ...props }) => {
    return <div className={'App pearson-jobs'}>
        {/* <App /> */}
        <DataProvider filterKeywords={FilterKeywords} currentLang={currentLang} homeitem={Navigation} navigation={!!Navigation?.Children ? Navigation.Children : []} xmlData={xmlData} {...props} >
            <ZoneRenderer currentLang={currentLang} contentZone={contentZone} withHeader={withHeader} withFooter={withFooter} />
        </DataProvider>
    </div>
}

const invokeApp = () => {
    const root = document.getElementById('pearson_jobs_root');
    const rootfooter = document.getElementById('pearson_jobs_footer');
    const currentLang = document.documentElement.lang;

    ReactDOM.render(
        <JobsApp currentLang={currentLang} withHeader={true} {...window.pearsonPageData} />,
        root
    );

    let reactcomponents = document.querySelectorAll('[data-react-component]')

    for (let i = 0; i < reactcomponents.length; ++i) {
        let target = reactcomponents[i];
        let componentName = target.getAttribute('data-react-component');
        ReactDOM.render(<JobsApp currentLang={currentLang} {...window.pearsonPageData} contentZone={[{
            ModuleName: "JobsSearchResults",
            props: {
                ...window.pearsonPageData[`${componentName}`]
            }
        }]} />, target);
    }

    ReactDOM.render(
        <JobsApp currentLang={currentLang} withFooter={true} {...window.pearsonPageData} />,
        rootfooter
    );
}

export default invokeApp;
