import React from 'react';

function JobsBanner({ ButtonLabel, ButtonUrl,  Description, Title}) {
    return (
        <>
            <section className={`employers-bar`}>
                <h2>{Title}</h2>
                <p>{Description}</p>
                <a href={ButtonUrl} className="btn btn-pill btn-theme-primary">{ButtonLabel}</a>
            </section>
        </>
    );
}

export default JobsBanner;
