import React, { useState, useEffect } from 'react';
import { useData } from "./../../contexts/DataContext";

function SearchPanel(props) {

    const { SearchLabel, SearchPlaceholder, SearchButtonLabel, currentLanguage
    } = props;

    const [searchError, setSearchError] = useState("");

    const { searchValue, setSearchValue } = useData();

    const handleSearch = (event) => {
        const searchText = event.target.value;
        setSearchValue(searchText);
    };

    useEffect(() => {
        if (!!searchValue) {
            setSearchError("");
        }
    }, [searchValue])

    const handleSubmit = (event) => {
        event.preventDefault();
        if (!!searchValue) {
            window.location.href = '/search?search=' + searchValue;
        } else {
            setSearchError(currentLanguage === 'en' ? "Search value not provided." : "Valeur de recherche non fournie.");
        }
        
    }

    return (
        <>
            <section className="panel-search">
                <div className={`page-width theme--home`}>
                    <p>{SearchLabel}</p>
                    <form className={`form-wrapper`}>
                        <div className={`input-wrapper`}>
                            <input type="text" placeholder={`${SearchPlaceholder}`} onChange={handleSearch} value={searchValue} />
                        </div>
                        <div className={`button-wrapper`}>
                            <button onClick={handleSubmit} className={`btn btn-pill btn-search`}>{SearchButtonLabel}</button>
                        </div>
                    </form>
                    {!!searchError && <span style={{ color: 'red', margin: '1rem 0', fontSize: '1rem', display: 'block' }}>{searchError}</span>}
                </div>
            </section>
        </>
    );
}


function HeroComponent(props) {
    const { HeroImage, Title, currentLanguage } = props;
    return (
        <>
            <figure style={{ backgroundImage: `url(${HeroImage})` }} className={`theme-home hero-bar`}>
                <figcaption className={'page-width'}>
                    <span>{Title}</span>
                </figcaption>
            </figure>
            <SearchPanel currentLanguage={currentLanguage} {...props} />
        </>
    );
}

export default HeroComponent;
