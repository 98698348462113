import React, { useState } from "react";
import { useData } from "./../../../contexts/DataContext";

function FiltersPanel(props) {
    const { Label, SearchButtonText, PlaceholderText, NoResultsLabel, FilterResultsLabel, SortByLabel,
        SortByRelevanceLabel, SortByDatePostedLabel, RelevanceSortLabel, DateSortLabel, PostedOnLabel,
        JobApplyButtonLabel,
        ViewJobDetailLabel,
        ShareButtonLabel, currentLang } = props;
    const { allJobs, jobsCount, filterJobs, searchValue, setSearchValue, jobDuration, setJobDuration,
        filteredJobs, clearFilters, companies = [], companySubFilter, setCompanySubFilter,
        dateSubFilter, setDateSubFilter,
        langSubFilter, setLangSubFilter, showMobileFilters, setShowMobileFilters, jobTypeFilter, setJobTypeFilter, labels } = useData();
    const [selectedCompany, setSelectedCompany] = useState("");

    const [showDatePosted, setShowDatePosted] = useState(true)
    const [showCompany, setShowCompany] = useState(true)
    const [showDuration, setShowDuration] = useState(true)
    const [showLang, setShowLang] = useState(true)
    const [showJobType, setShowJobType] = useState(true)


    const jobTypeFilters = [
        {
            "label": labels["Permanent"],
            "value": "permanent"
        },
        {
            "label": labels["Seasonal"],
            "value": "seasonal"
        },
        {
            "label": labels["Contract"],
            "value": "contract"
        }
    ];

    const jobTimeFilters = [
        {
            "name": labels['86400000'],
            "amount": 86400000
        },
        {
            "name": labels['259200000'],
            "amount": 259200000
        },
        {
            "name": labels['604800000'],
            "amount": 604800000
        },
        {
            "name": labels['1209600000'],
            "amount": 1209600000
        }
    ];


    return (
        <>
            <aside className={`filters-panel ${showMobileFilters ? 'mobile-open' : ''}`}>
                {((companySubFilter && !!companySubFilter.length) || !!langSubFilter || !!jobDuration || !!dateSubFilter || (jobTypeFilter && !!jobTypeFilter.length)) && <button onClick={clearFilters} className={`btn btn-pill btn-clear-filters`}>
                    {labels.clearFilter}
                    <svg width="10px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                        <path d="M310.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L160 210.7 54.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L114.7 256 9.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 301.3 265.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L205.3 256 310.6 150.6z" />
                    </svg>
                </button>}
                <h3>{FilterResultsLabel}</h3>
                <div>
                    <div className="filter-header">
                        <h4>{SortByDatePostedLabel}</h4>
                        <button aria-label={SortByDatePostedLabel + `${!!!showDatePosted ? " collapsed" : " expanded"}`} className={`${!!!showDatePosted ? "inactive" : ""}`} onClick={e => setShowDatePosted(!showDatePosted)}>
                            <svg aria-label={SortByDatePostedLabel + `${!!!showDatePosted ? " collapsed" : " expanded"}`} width={12} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                                <path d="M169.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 274.7 54.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                            </svg>
                        </button>
                    </div>

                    {!!showDatePosted && <ul>
                        {jobTimeFilters.map((filter, idx) => (
                            <li key={idx}>
                                <button onClick={e => {
                                    setDateSubFilter(dateSubFilter === filter.amount ? "" : filter.amount);
                                }} style={{ fontWeight: dateSubFilter === filter.amount ? "bold" : "normal" }}>
                                    {`${filter.name}`} {dateSubFilter === filter.amount ? "x" : ""}
                                </button>
                            </li>
                        ))}
                    </ul>}
                </div>
                <div>
                    <div className="filter-header">
                        <h4 className={'company-heading'}>
                            <span>{labels.company}</span>
                            <div className="relative">
                                <input aria-label="Search Company" type="text" value={selectedCompany} onChange={e => { setSelectedCompany(e.target.value) }} />
                                <svg className="magnify" width={12} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                    <path d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z" />
                                </svg>
                            </div>
                        </h4>
                        <button aria-label={`${labels.company}` + `${!!!showCompany ? " collapsed" : " expanded"}`} className={`${!!!showCompany ? "inactive" : ""}`} onClick={e => setShowCompany(!showCompany)}>
                            <svg aria-label={`${labels.company}` + `${!!!showCompany ? " collapsed" : " expanded"}`} width={12} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                                <path d="M169.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 274.7 54.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                            </svg>
                        </button>
                    </div>
                    {!!showCompany && <ul className={'company-filter'}>
                        {companies.sort().filter(c => {
                            return c.toLowerCase().includes(selectedCompany.toLowerCase());
                        }).map((company, key) => <li key={key} style={{
                            fontWeight: !!companySubFilter.includes(company) ? "bold" : "normal"
                        }}><button className="filter-item" style={{
                            textAlign: 'left'
                        }} onClick={e => {
                            e.preventDefault();
                            if (!!companySubFilter.includes(company)) {
                                setCompanySubFilter(companySubFilter.filter(c => c != company))
                            } else {
                                setCompanySubFilter([...companySubFilter, company])
                            }
                        }}>
                                {company} {!!companySubFilter.includes(company) ? "x" : ""}
                            </button></li>)}
                    </ul>}
                </div>
                <div>
                    <div className="filter-header">
                        <h4>{`${labels.jobDuration}`}</h4>
                        <button aria-label={`${labels.jobDuration}` + `${!!!showDuration ? " collapsed" : " expanded"}`} className={`${!!!showDuration ? "inactive" : ""}`} onClick={e => setShowDuration(!showDuration)}>
                            <svg aria-label={`${labels.jobDuration}` + `${!!!showDuration ? " collapsed" : " expanded"}`} width={12} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                                <path d="M169.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 274.7 54.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                            </svg>
                        </button>
                    </div>
                    {!!showDuration && <ul>
                        <li>
                            <button className="filter-item" style={{ fontWeight: jobDuration === "Full-Time" ? "bold" : "normal" }}
                                onClick={() => setJobDuration(jobDuration === "Full-Time" ? "" : "Full-Time")}>{currentLang === 'en' ? "Full-Time" : "Temps plein"} {jobDuration == "Full-Time" && "x"}</button>
                        </li>
                        <li>
                            <button className="filter-item" style={{ fontWeight: jobDuration === "Part-Time" ? "bold" : "normal" }}
                                onClick={() => setJobDuration(jobDuration === "Part-Time" ? "" : "Part-Time")}>{currentLang === 'en' ? "Part-Time" : "Temps partiel"} {jobDuration == "Part-Time" && "x"}</button>
                        </li>
                    </ul>}
                </div>
                <div>
                    <div className="filter-header">
                        <h4>{`${labels.jobLanguage}`}</h4>
                        <button aria-label={`${labels.jobLanguage}` + `${!!!showLang ? " collapsed" : " expanded"}`} className={`${!!!showLang ? "inactive" : ""}`} onClick={e => setShowLang(!showLang)}>
                            <svg aria-label={`${labels.jobLanguage}` + `${!!!showLang ? " collapsed" : " expanded"}`} width={12} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                                <path d="M169.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 274.7 54.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                            </svg>
                        </button>
                    </div>
                    {!!showLang && <ul>
                        <li style={{
                            fontWeight: "en" === langSubFilter ? "bold" : "normal"
                        }}><button className="filter-item" style={{
                            textAlign: 'left'
                        }} onClick={e => {
                            e.preventDefault();
                            setLangSubFilter(langSubFilter === "en" ? "" : "en");
                        }}>English {langSubFilter === "en" ? "x" : ""}</button></li>
                        <li style={{
                            fontWeight: "fr" === langSubFilter ? "bold" : "normal"
                        }}><button className="filter-item" style={{
                            textAlign: 'left'
                        }} onClick={e => {
                            e.preventDefault();
                            setLangSubFilter(langSubFilter === "fr" ? "" : "fr");
                        }}>French {langSubFilter === "fr" ? "x" : ""}</button></li>
                    </ul>}
                </div>
                <div>
                    <div className="filter-header">
                        <h4>{`${labels.jobType}`}</h4>
                        <button aria-label={`${labels.jobType}` + `${!!!showJobType ? " collapsed" : " expanded"}`} className={`${!!!showJobType ? "inactive" : ""}`} onClick={e => setShowJobType(!showJobType)}>
                            <svg aria-label={`${labels.jobType}` + `${!!!showJobType ? " collapsed" : " expanded"}`} width={12} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                                <path d="M169.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 274.7 54.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                            </svg>
                        </button>
                    </div>
                    {!!showJobType && <ul>
                        {jobTypeFilters.map((j, i) => <li key={i} style={{ fontWeight: !!jobTypeFilter.includes(j.value) ? "bold" : "normal" }}>
                            <button className="filter-item" style={{ textAlign: 'left' }} onClick={e => {
                                e.preventDefault();
                                if (!!jobTypeFilter.includes(j.value)) {
                                    setJobTypeFilter(jobTypeFilter.filter(c => c != j.value))
                                } else {
                                    setJobTypeFilter([...jobTypeFilter, j.value])
                                }
                            }}>{j.label} {!!jobTypeFilter.includes(j.value) ? "x" : ""}</button>
                        </li>)}
                    </ul>}
                </div>
                <div className={"mobile-apply"}>
                    <button onClick={e => {
                        e.preventDefault();
                        setShowMobileFilters(false);
                    }} className="btn btn-pill btn-theme-primary">{`${labels.applyFilter}`}</button>
                </div>
            </aside>
        </>
    );
}

export default FiltersPanel;
