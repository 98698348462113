import React, { useEffect, useRef } from 'react';
import * as focusTrap from 'focus-trap';
import Divider from './Divider';

const INDEEDJOBTITLES = [
    "Work Perks",
    "What You’ll Need",
    "Job description",
    "About Us",
    "Description",
    "Oversee and monitor all Customer Service activities such as, but not limited to",
    "A great is someone who",
    "Your responsibilities will include",
    "In your role, you will",
    "Responsibilities and Accountabilities",
    "Job Title",
    "Other duties include",
    "Position Summary",
    "What We Will Offer You",
    "This role would include",
    "We Will Offer You",
    "Monitors and follows up on all the Customer Services processes",
    "This Specialty Sales Associate Position Is For You, If You Would Enjoy",
    "The team is counting on you as a Specialty Sales Associate to",
    "Skills and Qualifications",
    "Skills & Qualifications",
    "Primary Job Functions",
    "Key Accountabilities",
    "Qualifications",
    "Role & Responsibilities",
    "About this Role",
    "How to Apply",
    "Join us at",
    "Why work at",
    "Skills and Abilities",
    "Department",
    "You are someone who",
    "Further information about the Company",
    "key values",
    "Salary",
    "Job Type",
    "Job Functions",
    "Job Segment",
    "Job Posting",
    "Job",
    "Primary Location",
    "Emplacement principal",
    "Organization",
    "Organisation",
    "Schedule",
    "Statut de l\"employé",
    "Language",
    "As our ideal candidate",
    "Offre d\"emploi publiée",
    "Date de retrait",
    "Equal opportunity statement",
    "Scope of Position",
    "Company policies",
    "Description",
    "Work Location",
    "You have a successful candidate possesses the following",
    "Various Miscellaneous duties",
    "Reports to",
    "Employee Status",
    "Pay",
    "Equal opportunity statement",
    "Job Segment",
    "Unposting Date",
    "Linguistic Requirements",
    "Requirements",
    "Salary",
    "Benefits & Perks Include",
    "Benefits & RRSP",
    "Benefits",
    "Knowledge, Skills, and Abilities",
    "Corporate Partnerships",
    "Diversity and Inclusion",
    "Equal Employment Opportunity Statement"
]

const JobModal = ({ data, onClose = () => { }, currentLang, labels, ...props }) => {
    
    function share() {
        const pathname = window.location.pathname;
        navigator.share({
            title: data.jobTitle,
            /*url: `${pathname}?jobId=${data.jobID}`,*/
            url: data.jobDetailsURL,
        })
            .then((_) => { return; })
            .catch((error) =>
                console.log(labels.shareErrorMessage, error)
            );
    }

    const closeHelper = () => {
        window.history.pushState({}, document.title, window.location.pathname);
        onClose()
    }

    const handleBackgroundClick = (e) => {
        if (!!!!e.target.classList.contains('job-modal')) {
            closeHelper()
        }
    }

    let trap: any;

    useEffect(() => {
        // handle focus trap
        console.log('on');
        trap = focusTrap.createFocusTrap(`#modaljob-${data.jobID}`);
        trap.activate()
        return function () {
            trap.deactivate();
            console.log('off');
        }
    }, [])

    return <div id={`modaljob-${data.jobID}`} className="job-description job-modal" onClick={handleBackgroundClick}>
        <div className='job-modal-content'>
            <div className='job-modal-header'>
                <button autoFocus onClick={() => closeHelper()} className={`back-results`}>{labels.backToSearch}</button>
            </div>
            <article className={'job-modal-content-detail'}>
                {!!data ? (
                    <section className={'job-details page-width'} tabIndex={-1}>
                        <h3 className={`job-title`}>{data.jobTitle}, {data.employerName} - {data.city}</h3>
                        <time>{new Intl.DateTimeFormat('en-US', { year: 'numeric', month: 'long', day: '2-digit' }).format(data.pubDate)}</time>
                        {/* <p>{data.jobDescription}</p> */}
                        <Divider string={data.jobDescription} titles={INDEEDJOBTITLES} />
                    </section>
                ) : (
                        <p>{labels.jobNotFound}</p>
                )}
            </article>
            {!!data && <div className={'job-modal-footer job-details-actions'}>
                <button
                    className={`btn btn-pill btn-theme-primary`}
                    onClick={() => window.open(data.jobDetailsURL, '_blank')}>{labels.applyNow}</button>
                <button
                    className={`btn btn-pill btn-theme-secondary`}
                    onClick={() => share()} dangerouslySetInnerHTML={{
                        __html: `
                        <svg width="10" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                            <path fill="currentColor" d="M352 224c53 0 96-43 96-96s-43-96-96-96s-96 43-96 96c0 4 .2 8 .7 11.9l-94.1 47C145.4 170.2 121.9 160 96 160c-53 0-96 43-96 96s43 96 96 96c25.9 0 49.4-10.2 66.6-26.9l94.1 47c-.5 3.9-.7 7.8-.7 11.9c0 53 43 96 96 96s96-43 96-96s-43-96-96-96c-25.9 0-49.4 10.2-66.6 26.9l-94.1-47c.5-3.9 .7-7.8 .7-11.9s-.2-8-.7-11.9l94.1-47C302.6 213.8 326.1 224 352 224z"/>
                        </svg>
                        <span style="margin-left: 6px">${labels.share}</span>
                    ` }} ></button>
            </div>}
        </div>
    </div>
}

export default JobModal
