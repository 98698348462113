import React from 'react';
import MainMenuComponent from "../../menus/main-menu/MainMenuComponent";
import { useData } from "./../../../contexts/DataContext";
import Logo from './../header/Logo'

function FooterComponent(props) {
    const { currentLang } = props;
    const { navItems, pageProps } = useData();
    return ( 
        <footer className="footer">
            <div className={`page-footer page-width`}>
                <div className="alt-logo">
                    <img src={pageProps?.footerLogo?.src} alt={pageProps?.footerLogo?.alt} />
                </div>
                <div className={`footer-nav`}>
                    <MainMenuComponent theme={'footer'}/>
                </div>
                <div className={`sub-nav`} dangerouslySetInnerHTML={{ __html: pageProps.footerComplianceLinks }}>
                </div>
                <div className={`copyright`}>
                    <span>{pageProps?.copyrightText}</span>
                </div>
            </div>
        </footer>
     );
}

export default FooterComponent;
