import React from 'react';
import { useData } from "./../../contexts/DataContext";


function JobsInnerPageHero (props) {
    const { HeroImage, TitleWithIcon, Title } = props;
    return (
        <>
            <figure style={{ backgroundImage: `url(${HeroImage})` }} className={`theme-inner hero-bar ${!!TitleWithIcon ? "" : "" }`}>
            </figure>
            {!!Title && <>
                {!!TitleWithIcon ? <section className={`page-header job-category`}>
                    <div className={`title-container`}>
                        <div className={`page-width`}>
                            <h1 className={`category-title`}>
                                {Title}
                            </h1>
                        </div>
                    </div>
                </section> : <div className={'page-width page-inner page-work-here'}>
                    <h1>{Title}</h1></div>}
            </>}
            
        </>
    );
}

export default JobsInnerPageHero;
