import React from 'react';
import { useData } from "./../../../contexts/DataContext";

function JobCard(props) {
    const { setJobModal } = useData();
    function limitWords(text, limit) {
        const words = text.split(' ');
        if (words.length <= limit) {
            return text;
        }
        return words.slice(0, limit).join(' ') + '...';
    }
    function openLinkInNewTab(url) {
        window.open(url, '_blank');
    }

    function share() {
        const pathname = window.location.pathname;
        navigator.share({
            title: props.job.jobTitle,
            //url: `${pathname}/?jobId=${props.job.jobID}`,
            url: props.job.jobDetailsURL,
        })
            .then((_) => console.log("Shared :)"))
            .catch((error) =>
                console.log("Share error :'(\n", error)
            );
    }

    return (
        <>
            <div className={`job-card`}>
                <h3 className={`job-title`}>{props.job.jobTitle}, {props.job.employerName} - {props.job.city}</h3>
                <time>{props.PostedOnLabel} {new Intl.DateTimeFormat('en-US', { year: 'numeric', month: 'long', day: '2-digit' }).format(props.job.pubDate)}</time>
                <p>{limitWords(props.job.jobDescription, 20)}</p>
                <nav className={"actions-holder"}>
                    <div className={`actions`}>
                        <button
                            className={`btn btn-pill btn-theme-primary`}
                            onClick={() => openLinkInNewTab(props.job.jobDetailsURL)}>{props.JobApplyButtonLabel}</button>
                        <a href={`/search?jobId=${props.job.jobID}`} onClick={e => {
                            e.preventDefault();
                            setJobModal(props.job);
                        }} style={{
                            marginRight: "1em"
                        }} className={`btn btn-pill btn-theme-secondary`}>{props.ViewJobDetailLabel}</a>
                    </div>

                    <div className={`action-share`}>
                        <button
                            className={`btn btn-pill btn-theme-secondary`}
                            onClick={() => share()}>
                            <svg width={10} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                <path fill="currentColor" d="M352 224c53 0 96-43 96-96s-43-96-96-96s-96 43-96 96c0 4 .2 8 .7 11.9l-94.1 47C145.4 170.2 121.9 160 96 160c-53 0-96 43-96 96s43 96 96 96c25.9 0 49.4-10.2 66.6-26.9l94.1 47c-.5 3.9-.7 7.8-.7 11.9c0 53 43 96 96 96s96-43 96-96s-43-96-96-96c-25.9 0-49.4 10.2-66.6 26.9l-94.1-47c.5-3.9 .7-7.8 .7-11.9s-.2-8-.7-11.9l94.1-47C302.6 213.8 326.1 224 352 224z" />
                            </svg>
                            <span style={{ marginLeft: "6px"}}>{props.ShareButtonLabel}</span>
                        </button>
                    </div>
                </nav>
            </div>
        </>
    );
}

export default JobCard;
