import React from 'react';
import { useData } from "./../../../contexts/DataContext";

function MainMenuComponent(props) {
    const { navItems } = useData();
    const page = 'home';
    return (
        <>
            <nav className={`main-menu page-${page} theme-${props.theme}`}>
                <ul className="main-nav-ul">
                    {navItems.map((item, idx) => {
                        return <li key={idx} className={!!item.Children && item.Children.length > 0 ? "has-subnav main-nav-li" : "main-nav-li"}>
                            <a href={item.Url} className={item.isActive ? 'active-link' : 'inactive-link' }>{item.Title}</a>
                            {!!item.Children && item.Children.length > 0 && <ul>
                                {item.Children.map((child, _idx) => <li key={_idx}>
                                    <a href={child.Url} className={child.isActive ? 'active-link' : 'inactive-link'}>{child.Title}</a>
                                </li>)}
                            </ul>}
                        </li>
                    })}
                </ul>
            </nav>
        </>
    );
}

export default MainMenuComponent;
