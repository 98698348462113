import React, { useState, useEffect } from "react";
import Logo from './Logo';
import { useData } from "./../../../contexts/DataContext";
import MainMenuComponent from "../../menus/main-menu/MainMenuComponent";


function HeaderComponent(props) {
    const [isOpen, setIsOpen] = useState(false);
    const [isMobile, setIsMobile] = useState(false);
    const { navItems, breadcrumbs, pageProps } = useData();
    const [altLanguagePath, setAltLanguagePath] = useState('');
    let page = 'home';

    const { currentLang } = props;
    const { languageSwitcher, headerLogo } = pageProps;

    // detect if mobile on component mount or when the window is resized
    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth < 768) {
                setIsMobile(true);
            } else {
                setIsMobile(false);
            }
        }
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    // set alt language path
    useEffect(() => {
        let path = window.location.pathname;
        let targetLang = currentLang === 'en' ? 'fr' : 'en';
        let targetpath = path.replace(`/${currentLang === 'en' ? currentLang : 'fr'}`, `/${targetLang}`);
        console.log(path, currentLang, targetLang, targetpath);
        setAltLanguagePath(targetpath);
    }, []);

    return (<><header className="header" style={{ zIndex: 2 }}>
        {isMobile ? (<>
            <div className={'mobile-header'} id={'jobs-header-logo'}>
                <a href="/" className={'mobile-logo'}>
                    <Logo />
                </a>
                <button aria-expanded="false" aria-label={isOpen ? 'Menu Expanded' : 'Menu Collapse'} className="menu-toggle" onClick={e => {
                    e.preventDefault();
                    setIsOpen(!isOpen);
                }}>
                    {isOpen ? 'x' : '='}
                </button>
            </div>
            {isOpen && <nav className={'mobile-menu'}>
                <a className={"langmenu-button"} href="https://torontopearson.com">torontopearson.com</a>
                {currentLang === 'en' ? <a className={"langmenu-button"} href="/fr">Francais</a> : <a className={"langmenu-button"} href="/en">English</a>}
                <ul className={"mobile-list"}>
                    {navItems.map((item, idx) => {
                        return <li key={idx}>
                            <a href={item.Url}>{item.Title}</a>
                            {!!item.Children && <ul>
                                {item.Children.map((child, _idx) => {
                                    return <li key={_idx}><a href={child.Url}>{child.Title}</a></li>
                                })}
                            </ul>}
                        </li>
                    })}
                </ul>
            </nav>}
        </>) : (
            <div className={`page-${page} page-header`}>
                <div className={`page-width menu-wrapper`}>
                        <a href={headerLogo?.link} className={'main-logo'} id={'jobs-header-logo'}>
                            <img src={headerLogo?.src} alt={headerLogo?.alt} />
                    </a>
                    <div className={`fixedarea`}>
                            <a className={`langmenu-button`} href={languageSwitcher?.TPlink}>{languageSwitcher?.TPhostname}</a>
                            <a className={"langmenu-button"} href={languageSwitcher?.link}>{languageSwitcher?.langname}</a>
                    </div>
                    <MainMenuComponent theme={'header'} page={page} />
                </div>
            </div>)}
    </header>
        {!!breadcrumbs && breadcrumbs.length > 1 && <div id={'jobs-breadcrumb'} className={'breadcrumb'}>
            <ul className="page-width">
                {breadcrumbs.map((crumb, idx) => {
                    return (
                        <li key={idx}>
                            <div className={`spacer`}>/</div>
                            <a href={crumb.Url}>
                                <span>{crumb.Title}</span>
                            </a>
                        </li>
                    )
                })}
            </ul>
        </div>}
    </>);
}

export default HeaderComponent;
