import React from 'react';

const EmployerList = ({ Title, Description, EmployerList}) => {
    return (
        <>
        <div className={'page-width'}>
            <section className={`section-employer`}>
                    <div className={`heading`}>
                        <h2 dangerouslySetInnerHTML={{ __html: Title }}></h2>
                        <p dangerouslySetInnerHTML={{ __html: Description }}></p>
                </div>
                    {EmployerList.map((employer, key) => {
                    return (<div className={`card-employer`}>
                        <figure className={`employer-logo`}>
                            <img alt={employer.Title} src={`${employer.Logo}`} />
                        </figure>
                        <figcaption className={`employer-details`}>
                            <h3>
                                <a href={employer.Url} target="_blank" dangerouslySetInnerHTML={{ __html: employer.Title }}></a>
                            </h3>
                            <p dangerouslySetInnerHTML={{ __html: employer.Description }}>
                            </p>
                        </figcaption>
                    </div>)
                })}
                </section>
        </div>
        </>
    );
}

export default EmployerList;
