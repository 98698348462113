import React from 'react';

function JobsBannerWithImage(props) {
    const { Image, Description, Title } = props;
    return (
        <>
            <div className={`work-here-card align`}>
                <figure>
                    <img src={Image} alt={Title} />
                    <figcaption>
                        <div className={`text-wrapper`}>
                            <h3>{Title}</h3>
                            <p>{Description}</p>
                        </div>
                    </figcaption>
                </figure>
            </div>
        </>
    );
}

export default JobsBannerWithImage;
