import React from 'react';
import SearchBar from './../bars/search/SearchBar';
import ResultsPanel from './../panels/results/ResultsPanel';
import FiltersPanel from './../panels/filters/FiltersPanel'

const JobsSearchResults = (props) => {
    const { Label, SearchButtonText, PlaceholderText, NoResultsLabel, FilterResultsLabel, SortByLabel, SortByRelevanceLabel, SortByDatePostedLabel, RelevanceSortLabel, DateSortLabel, PostedOnLabel,
        JobApplyButtonLabel,
        ViewJobDetailLabel,
        FilterKeywords,
        ShareButtonLabel, Description, Image, currentLang } = props;
    return <div>
        {!!Description && <div className="job-category">
            <section className={`page-header`}>
            <div className={`page-width category-info`}>
                <div className={`category-details`} dangerouslySetInnerHTML={{ __html: Description }} >
                </div>
                <div className={`category-video`}>
                    <img src={Image} alt="" />
                </div>
            </div>
            </section>
        </div>}
        <SearchBar heading={Label} button={SearchButtonText} placeholder={PlaceholderText} />
        <article className={'page-width search-results'} id={'jobs-searchresults'}>
            <FiltersPanel {...props} currentLang={currentLang} />
            <ResultsPanel {...props} currentLang={currentLang} />
        </article>
    </div>
}

export default JobsSearchResults;
