import React from 'react';

const Divider = ({ string, titles }) => {
	let sections: any[] = [];
	let start = 0;

	// Loop through the titles and find their indexes in the string
	titles.forEach((title) => {
		const index = string.indexOf(title, start);
		if (index !== -1) {
			// If the title has content, push it to the sections array
			const content = string.substring(start, index);
			if (content.trim()) {
				sections.push({ title: null, content });
			}
			sections.push({ title, content: '' });
			start = index + title.length;
		}
	});

	// Push the remaining content to the sections array
	const remainingContent = string.substring(start);
	if (remainingContent.trim()) {
		sections.push({ title: null, content: remainingContent });
	}

	// Add space after every period
	const regexDotOrQuestion = /\.|\?(?=\S)/g;
	const regexColon = /^:/;
	const regexCapsWithSpace = /([a-z])([A-Z])/g;
	const regexStar = /\*/g;
	const regexExclamation = /(!)(?!\s)/g;
	const regexHttp = /([^ ])(http)/g;
	sections.forEach((section) => {
		section.content = section.content
			.replace(regexDotOrQuestion, '. ')
			.replace(regexColon, '')
			.replace(regexCapsWithSpace, '$1 $2')
			.replace(/&gt;|&lt;/g, '')
			.replace(regexStar, (match, p1, offset, string) => {
				if (
					offset > 0 &&
					offset < string.length - 1 &&
					string[offset - 1].match(/[a-zA-Z]/) &&
					string[offset + 1].match(/[a-zA-Z]/)
				) {
					return ' ';
				} else {
					return '';
				}
			})
			.replace(regexExclamation, '$1 ')
			.replace(regexHttp, '$1 $2');
		// Divide content with a bullet character (•) into an unordered list
		try {
			if (section.content.includes('•')) {
				const contentList = section.content.split('•');
				const listItems = contentList.map((item, index) => {
					const trimmedItem = item.trim();
					return trimmedItem ? <li key={index}>{trimmedItem}</li> : null;
				});
				section.content = <ul>{listItems}</ul>;
			}

			// Divide content with " character before and " after into separate div tags
			if (section.content.includes('"')) {
				const contentList = section.content.split('"');
				const listItems = contentList.map((item, index) => {
					const trimmedItem = item.trim();
					return trimmedItem ? <li key={index}>{trimmedItem}</li> : null;
				});
				section.content = <ul>{listItems}</ul>;
			}

			// Divide content with a semicolon (;) into separate divs
			if (section.content.includes(';')) {
				const contentList = section.content.split(';');
				const listItems = contentList.map((item, index) => {
					const trimmedItem = item.trim();
					return trimmedItem ? <li key={index}>{trimmedItem}</li> : null;
				});
				section.content = <ul>{listItems}</ul>;
			}


		} catch (error) {

		}

	});

	return (
		<>
			{sections.map((section, index) => {
				const { title, content } = section;
				return (
					<div key={index}>{title ? <h4>{title}</h4> : <>{content}</>}</div>
				);
			})}
		</>
	);
};

export default Divider;
