import React from 'react';
import { useData } from "./../../../contexts/DataContext";
import JobCard from './JobCard'

function ResultsPanel(props) {
    const { Label, SearchButtonText, PlaceholderText, NoResultsLabel, FilterResultsLabel, SortByLabel,
        SortByRelevanceLabel, SortByDatePostedLabel, RelevanceSortLabel, DateSortLabel, PostedOnLabel,
        JobApplyButtonLabel,
        ViewJobDetailLabel,
        ShareButtonLabel, currentLang } = props;
    const defaultOffset = 10;
    const { allJobs, jobsCount, filterJobs, searchValue, setSearchValue, setJobType, jobType,
        filteredJobs, clearFilters, companies = [], companySubFilter, setCompanySubFilter,
        jobDuration, setJobDuration,
        dateSubFilter, setDateSubFilter,
        setSortBy, sortBy,
        offset, setOffset,
        langSubFilter, setLangSubFilter, showMobileFilters, setShowMobileFilters, jobTypeFilter, setJobTypeFilter, labels } = useData();
    const subFilteredJobs = filteredJobs.filter(j => {
        {/* filter for company if company subfilter is active */ }
        if (!!companySubFilter && !!companySubFilter.length) {
            return !!companySubFilter.includes(j.employerName)
        }
        return true;
    }).filter(j => {
        {/* filter for language if language subfilter is active */ }
        if (langSubFilter) {
            return j.language === langSubFilter;
        }
        return true;
    }).filter(j => {
        {/* filter for date if date subfilter is active */ }
        if (dateSubFilter) {
            return new Date().getTime() - Number(j.pubDate) <= Number(dateSubFilter);
        }
        return true;
    }).filter(j => {
        {/* filter for date if date subfilter is active */ }
        if (jobDuration) {
            return j.jobTime.toLowerCase() == jobDuration.toLowerCase();
        }
        return true;
    }).filter(j => {
        {/* filter for jobtype if jobtype subfilter is active */ }
        if (!!jobTypeFilter && !!jobTypeFilter.length) {
            return !!jobTypeFilter.includes(j.jobType.toLowerCase());
        }
        return true;
    }).sort((a, b) => {
        // sort by date if sortBy === "date"
        if (sortBy === "date") {
            return Number(b.pubDate) - Number(a.pubDate);
        } else if (sortBy === "title") {
            return a.jobTitle.localeCompare(b.jobTitle);
        }
    });

    const jobTimeFilters = [
        {
            "name": labels['86400000'],
            "amount": 86400000
        },
        {
            "name": labels['259200000'],
            "amount": 259200000
        },
        {
            "name": labels['604800000'],
            "amount": 604800000
        },
        {
            "name": labels['1209600000'],
            "amount": 1209600000
        }
    ];

    let curFilterJobTime = jobTimeFilters.filter(time => Number(time.amount) == Number(dateSubFilter))
    const searchOptions = `${!!searchValue ? searchValue : ""} ${!!companySubFilter && !!companySubFilter.length ? `${labels.in} "${companySubFilter.join(", ")}"` : ""} ${!!curFilterJobTime && !!curFilterJobTime.length ? `${labels.in} "${curFilterJobTime[0].name}"` : ""} ${!!jobType ? `${labels.in} "${jobType}"` : ""} ${langSubFilter ? langSubFilter === "fr" ? labels.inFrench : labels.inEnglish : ``} ${!!jobTypeFilter && !!jobTypeFilter.length ? `${labels.in} "${jobTypeFilter.join(', ')}"` : ""}`
    let jobNavigations = Array.from({ length: Math.ceil(subFilteredJobs.length / defaultOffset) }, (v, i) => i + 1)
    const lastNavOffset = subFilteredJobs.length - Math.ceil(subFilteredJobs.length % defaultOffset)
    let previousOffset = offset < (defaultOffset * 2) ? 0 : (offset > (lastNavOffset - (defaultOffset * 2)) ? (lastNavOffset - (defaultOffset * 2)) : offset - defaultOffset)
    let nextOffset = offset < (defaultOffset * 2) ? (defaultOffset * 2) : (offset > (lastNavOffset - (defaultOffset * 2)) ? (lastNavOffset + (defaultOffset * 2)) : offset + defaultOffset)

    const sortByItems = [
        {
            "label": SortByDatePostedLabel,
            "value": "date"
        }, {
            "label": SortByRelevanceLabel,
            "value": "relevance"
        }
    ];
    return (
        <>
            <section className={`panel-results`}>
                <div className="results-context results-context-holder">
                    <div className="results-context results-count">{subFilteredJobs.length} {labels.results}</div>
                    <div className={'results-context'}>
                        <button className={`mobile-filters btn btn-pill btn-clear-filters`} onClick={e => {
                            e.preventDefault();
                            setShowMobileFilters(!showMobileFilters);
                        }}>{FilterResultsLabel}</button>
                        <div className={"sort-container"}>
                            {SortByLabel}
                            {sortByItems.map((item, i) => <button key={i} className={`sort-button ${sortBy === item.value ? "active" : ''}`} onClick={e => {
                                e.preventDefault();
                                setSortBy(item.value);
                            }}>{item.label}</button>)}
                        </div>
                    </div>
                </div>
                {!!subFilteredJobs.length ? subFilteredJobs.filter((j, i) => i + 1 >= offset && i + 1 < offset + (defaultOffset + 1) && !!j).map((job, key) => <JobCard key={key} job={job} labels={labels} {...props} />)
                    : <div style={{ fontSize: "1.5rem" }}>
                        <div className="no-results-with-criteria" style={{ lineHeight: 1.6 }}>
                            {NoResultsLabel}:
                            {!!searchValue ? <button className="search-criteria" onClick={clearFilters}>{searchValue} {'\u00d7'}</button> : ""}
                            {!!jobDuration ? <button className="search-criteria" onClick={e => setJobDuration("")}>{jobDuration} {'\u00d7'}</button> : ""}
                            {!!dateSubFilter && <button className="search-criteria" onClick={e => setDateSubFilter("")}>
                                {jobTimeFilters.find(f => f.amount === dateSubFilter)?.name} {'\u00d7'}
                            </button>}
                            {!!companySubFilter && companySubFilter.length > 0 ? companySubFilter.map((company, idx) => {
                                return <button key={idx} className="search-criteria" onClick={e => {
                                    if (!!companySubFilter.includes(company)) {
                                        setCompanySubFilter(companySubFilter.filter(c => c != company))
                                    } else {
                                        setCompanySubFilter([...companySubFilter, company])
                                    }
                                }}>{company} {'\u00d7'}</button>
                            })
                                : ``}
                            {!!langSubFilter ? <button className="search-criteria" onClick={e => setLangSubFilter("")}>{langSubFilter === "fr" ? `French` : `English`} {'\u00d7'}</button> : ``}
                            {!!jobTypeFilter ? jobTypeFilter.map((jt, idx) => <button key={idx} className="search-criteria" onClick={e => {
                                if (!!jobTypeFilter.includes(jt)) {
                                    setJobTypeFilter(jobTypeFilter.filter(c => c != jt))
                                } else {
                                    setJobTypeFilter([...jobTypeFilter, jt])
                                }
                            }}>{jt} {'\u00d7'}</button>) : ""}
                        </div>
                        <div className="search-no-results-spacer"></div>
                    </div>}
                {!!subFilteredJobs && subFilteredJobs.length > defaultOffset && <div className="results-pagination">
                    <div className="pagination-button-wrapper">
                        {offset > 0 && <>
                            {/*<button className="results-pagination__button" onClick={e => {
                                e.preventDefault();
                                setOffset(0)
                            }}>
                                First
                            </button>*/}
                            <button aria-label="Previous" className="results-pagination__button back" onClick={e => {
                                e.preventDefault();
                                offset > 0 && setOffset(offset - defaultOffset);
                            }}>
                                <svg width={10} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512">
                                    <path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L77.3 256 214.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z" />
                                </svg>
                            </button>
                        </>}
                    </div>
                    <div className="results-pagination__pages overflow-scroll-x">
                        <button aria-label={`Page 1 ${offset === 0 ? "selected" : ""}`} className={`results-pagination__button ${offset === 0 ? "active" : ""}`} onClick={e => {
                            e.preventDefault();
                            setOffset(0);
                        }}>1</button>
                        {offset > (defaultOffset * 2) && <span>...</span>}
                        {jobNavigations.map((page, i) => {
                            let curNavOffset = (page - 1) * defaultOffset
                            if (i > 0 && i < (jobNavigations.length - 1) && (curNavOffset >= previousOffset && curNavOffset <= nextOffset)) {
                                return <button aria-label={`Page ${page} ${offset === curNavOffset ? "selected" : ""}`} key={i} className={`results-pagination__button ${offset === curNavOffset ? "active" : ""}`} onClick={e => {
                                    e.preventDefault();
                                    setOffset(curNavOffset);
                                }}>
                                    {page}
                                </button>
                            }
                            return false
                        })}
                        {offset < (lastNavOffset - (defaultOffset * 2)) && <span>...</span>}
                        <button aria-label={`Page ${Math.ceil(subFilteredJobs.length / defaultOffset)} ${offset === lastNavOffset ? "selected" : ""}`} className={`results-pagination__button ${offset === lastNavOffset ? "active" : ""}`} onClick={e => {
                            e.preventDefault();
                            setOffset(lastNavOffset);
                        }}>{Math.ceil(subFilteredJobs.length / defaultOffset)}</button>
                    </div>
                    <div className="pagination-button-wrapper">
                        {offset < lastNavOffset && <>
                            <button aria-label="Next" className="results-pagination__button next" onClick={e => {
                                e.preventDefault();
                                offset + defaultOffset < subFilteredJobs.length && setOffset(offset + defaultOffset);
                            }}>
                                <svg width={10} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512">
                                    <path d="M246.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-160 160c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L178.7 256 41.4 118.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l160 160z" fill="currentColor" />
                                </svg>
                            </button>
                            {/*<button className="results-pagination__button" onClick={e => {
                                e.preventDefault();
                                setOffset(lastNavOffset)
                            }}>
                                Last
                            </button>*/}
                        </>}
                    </div>
                </div>}
            </section>
        </>
    );
}

export default ResultsPanel;
