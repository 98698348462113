import React from "react"

const Logo = (props) => (
    <svg
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        x={0}
        y={0}
        viewBox="0 0 211 33"
        style={{
            enableBackground: "new 0 0 211 33",
        }}
        xmlSpace="preserve"
        {...props}
    >
        <style>{".st0{fill-rule:evenodd;clip-rule:evenodd;fill:#002663}"}</style>
        <g id="Symbols">
            <g id="Header---Tablet" transform="translate(-32 -19)">
                <g id="Group-3" transform="translate(32 19)">
                    <g id="Group" transform="translate(0 10)">
                        <path
                            id="Fill-12"
                            className="st0"
                            d="M9.4 9.9c0-3.2 2.1-5.6 5.2-5.6s5.2 2.4 5.2 5.6c0 3.2-2.1 5.6-5.2 5.6-3.1-.1-5.2-2.5-5.2-5.6m7.9 0c0-2.1-1-3.6-2.8-3.6-1.8 0-2.8 1.5-2.8 3.6s1 3.7 2.8 3.7c1.8-.1 2.8-1.6 2.8-3.7"
                        />
                        <path
                            id="Fill-14"
                            className="st0"
                            d="M21.7 4.6h2l.3 1.2c1-1.1 1.9-1.5 2.9-1.5.5 0 1 .1 1.4.4l-.8 2c-.4-.2-.8-.3-1.1-.3-.7 0-1.3.3-2.3 1.2v7.6h-2.3V4.6z"
                        />
                        <path
                            id="Fill-16"
                            className="st0"
                            d="M28.5 9.9c0-3.2 2.1-5.6 5.2-5.6s5.2 2.4 5.2 5.6c0 3.2-2.1 5.6-5.2 5.6-3.1-.1-5.2-2.5-5.2-5.6m8 0c0-2.1-1-3.6-2.8-3.6-1.8 0-2.8 1.5-2.8 3.6s1 3.7 2.8 3.7c1.8-.1 2.8-1.6 2.8-3.7"
                        />
                        <path
                            id="Fill-18"
                            className="st0"
                            d="M40.8 4.6h2l.2 1.2c1.1-.9 2.3-1.5 3.6-1.5 1.7 0 3.3.9 3.3 3.8v7.1h-2.3V8.9c0-1.4-.3-2.5-1.7-2.5-1 0-2 .6-2.8 1.3v7.6h-2.3V4.6z"
                        />
                        <path
                            id="Fill-20"
                            className="st0"
                            d="M53.3 12.7V6.4h-1.9V4.6h1.9V2.1l2.3-.7v3.2h3.1v1.8h-3.1v5.8c0 1 .4 1.4 1.1 1.4.7 0 1.2-.2 1.7-.5l.7 1.6c-.7.5-1.9.8-3 .8-1.7-.1-2.8-1.1-2.8-2.8"
                        />
                        <path
                            id="Fill-22"
                            className="st0"
                            d="M59.6 9.9c0-3.2 2.1-5.6 5.2-5.6S70 6.7 70 9.9c0 3.2-2.1 5.6-5.2 5.6-3.1-.1-5.2-2.5-5.2-5.6m8 0c0-2.1-1-3.6-2.8-3.6C63 6.3 62 7.8 62 9.9s1 3.7 2.8 3.7c1.8-.1 2.8-1.6 2.8-3.7"
                        />
                        <path
                            id="Fill-24"
                            className="st0"
                            d="M86.5 9.9c0-3.2 1.8-5.6 4.7-5.6 2.9 0 4.6 2.2 4.6 5.6v.6H89c0 1.8 1.2 3.1 3.2 3.1 1.1 0 1.9-.3 2.7-.7l.7 1.6c-1 .7-2.4 1-3.6 1-3.9-.1-5.5-2.7-5.5-5.6zm6.9-1.1c0-1.4-.7-2.7-2.2-2.7-1.4 0-2.3 1.2-2.3 2.7h4.5z"
                        />
                        <path
                            id="Fill-26"
                            className="st0"
                            d="M103.9 14.1c-.8.7-1.8 1.3-3.1 1.3-2 0-3.5-1.3-3.5-3.4 0-1.4.7-2.3 1.8-2.9.7-.4 1.6-.5 2.7-.6l1.9-.1v-.3c0-1.4-.9-2-2.1-2-1.1 0-2.1.4-2.8.8L98 5.3c1.3-.7 2.6-1.1 4-1.1 2.6 0 4.1 1.2 4.1 3.8v4.1c0 1.5 0 2.5.2 3h-2l-.4-1zm-1.8-3.9c-.8 0-1.3.1-1.7.4-.5.3-.7.7-.7 1.3 0 1 .7 1.6 1.7 1.6 1.1 0 1.9-.6 2.3-1v-2.4l-1.6.1z"
                        />
                        <path
                            id="Fill-28"
                            className="st0"
                            d="M108.6 4.6h2l.3 1.2c1-1.1 1.9-1.5 2.9-1.5.5 0 1 .1 1.4.4l-.8 2c-.4-.2-.8-.3-1.1-.3-.7 0-1.4.3-2.3 1.2v7.6h-2.3V4.6z"
                        />
                        <path
                            id="Fill-30"
                            className="st0"
                            d="M125 9.9c0-3.2 2.1-5.6 5.2-5.6s5.2 2.4 5.2 5.6c0 3.2-2.1 5.6-5.2 5.6-3.1-.1-5.2-2.5-5.2-5.6m8 0c0-2.1-1-3.6-2.8-3.6-1.8 0-2.8 1.5-2.8 3.6s1 3.7 2.8 3.7c1.8-.1 2.8-1.6 2.8-3.7"
                        />
                        <path
                            id="Fill-32"
                            className="st0"
                            d="M137.3 4.6h2l.3 1.3c1.1-.9 2.3-1.5 3.6-1.5 1.7 0 3.3.9 3.3 3.8v7.1h-2.3V8.9c0-1.4-.3-2.5-1.7-2.5-1 0-2 .6-2.8 1.3v7.6h-2.3V4.6z"
                        />
                        <path
                            id="Fill-34"
                            className="st0"
                            d="M122.5 6.7c-.8-.3-1.6-.6-2.6-.6-.9 0-1.6.3-1.6 1.1 0 1.9 5.2 1.2 5.2 4.7 0 2.4-2 3.4-4.3 3.4-1.6 0-2.9-.5-3.7-1l.7-1.7c.7.5 1.7.9 2.8.9 1.2 0 2.1-.4 2.1-1.5 0-1.9-5.2-1.2-5.2-4.6 0-1.8 1.5-3.1 4-3.1 1.2 0 2.3.2 3.1.5l-.5 1.9z"
                        />
                        <path
                            id="Fill-36"
                            className="st0"
                            d="M4.3 2.5H0V.6h11v1.9H6.7v12.7H4.3z"
                        />
                        <path
                            id="Fill-38"
                            className="st0"
                            d="M83.1 1.2c-.9-.5-2-.7-3.4-.7h-3.5v14.6h2.4v-4.7h1c1.4 0 2.4-.2 3.2-.6 2-.9 2.8-2.6 2.8-4.4-.1-1.9-.9-3.4-2.5-4.2m-1.4 7c-.5.3-1.1.4-2 .4h-1.1V2.5h1.1c.9 0 1.6.2 2.1.5.9.5 1.3 1.5 1.3 2.6 0 1.2-.5 2.1-1.4 2.6"
                        />
                    </g>
                    <g id="Group-5" transform="translate(163)">
                        <path
                            id="Shape"
                            d="M46.7 9.9c-3.8-1.2-9-2.1-11.8-2.3-4.2-.2-6.6.8-7.1 4.9-.8 5.7-.8 11-.5 16.9 0 .7.6 1.3 1.4 1.2.8 0 1.3-.6 1.3-1.4-.2-5.2-.4-11.4.2-16.5.1-1.2.4-2 .8-2.6 4.5 3 7.9 6.2 11.3 10.2.7.8 1.2.6 1.6.2.4-.6.2-1.2-.3-1.7-3.3-4.1-6.8-7.1-11-9.7.7-.2 1.4-.2 2.3-.1 2.7.1 7.9 1 12 2.2.4.1.7-.1.7-.5-.1-.3-.4-.6-.9-.8"
                            style={{
                                fill: "#b3db11",
                            }}
                        />
                        <path
                            id="Shape_00000124866982488385710010000012468901836534805137_"
                            d="M23.7 32.6c.7-.2 1.3-.9 1.2-1.7-.9-4.2-1.7-8.6-2.1-12.7-.2-1.6-.7-3-2.5-3.3-1.5-.3-3.6.5-5.6 1.7-5 3-9.6 7.4-12.5 12.9-.4.7-.2 1.3.1 1.7.4.7.9.6 1.2.1C6.3 25.8 10.9 21 15.8 18c1.7-1.1 2.8-1.3 3.3-1.2.8.1 1.2.8 1.3 2.1.4 4.1 1 8.5 1.8 12.6.1.9.6 1.3 1.5 1.1"
                            style={{
                                fill: "#4dbdd7",
                            }}
                        />
                        <path
                            id="Shape_00000015350683826757120540000008891442170823384744_"
                            d="M20.6 8c-.2-.7-1.5-.8-4.2-.6-2.6.2-5.1.6-8.1 1.3-.6.2-1.1.4-1.3.8-.3.4-.1.7.4.5 2.7-.7 6.5-1.3 9.2-1.4h.2c-5.3 1.7-10.6 4.3-14.9 7.8-.5.4-.6.7-.6 1.1 0 .5.2.7.5.4 4.5-3.8 10.8-6.7 15.7-8.4-.1.1-.3.2-.4.3C10.4 14.1 4.5 18.4.4 26.2c-.3.5-.2 1 0 1.3.3.5.6.5.8.1 4.2-8.1 9.9-12.2 17-17 2.3-1.5 2.6-2 2.4-2.6"
                            style={{
                                fill: "red",
                            }}
                        />
                        <path
                            id="Shape_00000038388237654468610160000011100073133874867891_"
                            d="M47 7.9c-.1-.3-.4-.6-.9-.8-4-1.1-7.8-1.7-11.4-2h-.5c2.6-.7 5.5-1.1 8.1-1.4.3 0 .5-.3.2-.6s-.7-.4-1.1-.3c-2.6.2-5.5.8-8 1.5h-.1l.6-.6c1.6-1.5 1.1-2.5 0-2.9-1.4-.6-3.1-.4-4.1.1-.3.2-.2.5.2.7.3.2.9.1 1.3 0 .6-.2 1.1-.2 1.6 0 .7.3.6.8-.4 1.6-.8.7-1.4 1.4-.8 2.1.6.6 1.8.9 2.8 1 3.6.4 8 1.1 12.1 2.3.3-.1.5-.3.4-.7"
                            style={{
                                fill: "#ffb300",
                            }}
                        />
                        <path
                            id="Shape_00000087378227934132756760000007201746363057228213_"
                            d="M23.1 5.6c4.1-.2 4.8-1.3 5.6-2.4.1-.2.2-.3.3-.4.2-.3.1-.6-.4-.7-.4-.1-1 0-1.3.4-.1.1-.1.2-.2.2-.7.8-1.2 1.9-4.1 2-4 .1-7.4.5-10.3 1.1-.8.1-1.2.4-1.4.7-.2.3-.3.6.2.5 3.7-.8 7.2-1.2 11.6-1.4"
                            style={{
                                fill: "#00184a",
                            }}
                        />
                    </g>
                </g>
            </g>
        </g>
    </svg>
)

export default Logo
